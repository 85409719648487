@import url('https://fonts.googleapis.com/css2?family=Ranchers&display=swap');

.GameRoom-body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #220c10;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='280' height='280' viewBox='0 0 80 80'%3E%3Cg fill='%23dab6fc' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M11 0l5 20H6l5-20zm42 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM0 72h40v4H0v-4zm0-8h31v4H0v-4zm20-16h20v4H20v-4zM0 56h40v4H0v-4zm63-25a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM53 41a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-30 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-28-8a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zM56 5a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zm-3 46a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM21 0l5 20H16l5-20zm43 64v-4h-4v4h-4v4h4v4h4v-4h4v-4h-4zM36 13h4v4h-4v-4zm4 4h4v4h-4v-4zm-4 4h4v4h-4v-4zm8-8h4v4h-4v-4z'/%3E%3C/g%3E%3C/svg%3E");
}

.room-name {
    position: absolute;
    top: 5vh;
    margin: auto;
    letter-spacing: calc(1px + 1vmin);
    font-size: calc(2rem + 4vmin);
    font-family: 'Ranchers', cursive;
    text-shadow: calc(8px + .25vmin) calc(8px + .25vmin) black;
    color: #ea484e;
}

.waiting-message {
    font-size: calc(25px + 2vmin);
    text-shadow: calc(4px + .25vmin) calc(4px + .25vmin) black;
    color: #F5E4E0;
    font-family: 'Ranchers', cursive;
}

.start-game {
    width: calc(230px + 2vmin);
    margin: auto;
    height: calc(50px + 2vmin);
    font-size: calc(7px + 2vmin);
    border-radius: calc(5px + .25vmin);
    background-color: #76E5FC;
    box-shadow: calc(2px + .25vmin) calc(2px + .25vmin) #04B8DC;
    border: none;
    color: #220c10;
    font-family: 'Ranchers', cursive;
}

.start-game:hover {
    cursor: pointer;
}

@media screen and (max-width: 550px) {
    .waiting-message {
        font-size: calc(15px + 2vmin);
    }
}