@import url('https://fonts.googleapis.com/css2?family=Ranchers&display=swap');

.home {
    text-align: center;
}
  
.home-body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.title-wrapper {
    text-align: center;
    margin-top: 5vh;
}

.title-logo {
    width: 40%;
    box-shadow: calc(1px + 1vmin) calc(1px + 1vmin) 15px black;
    border-radius: calc(15px + 2vmin);
}

.title-header {
    margin-bottom: 0;
    margin: auto;
    letter-spacing: calc(1px + 1vmin);
    font-size: calc(7px + 2vmin);
    font-weight: 900;
    color: black;
    font-family: 'Ranchers', cursive;
    color: white;
    text-shadow: calc(2px + .25vmin) calc(2px + .25vmin) black;
    margin-top: calc(5px + 2vmin);
}

.title {
    margin-bottom: 0;
    margin-top: 0;
    margin: auto;
    letter-spacing: calc(1px + 1vmin);
    font-size: calc(2rem + 4vmin);
    font-family: 'Ranchers', cursive;
    text-shadow: calc(8px + .25vmin) calc(8px + .25vmin) black;
    color: #ea484e;
}

.join-seperator {
    font-size: calc(5px + 2vmin);
    margin-top: calc(5px + 1vmin);
    margin-bottom: calc(5px + 1vmin);
    text-shadow: calc(2px + .25vmin) calc(2px + .25vmin) black;
    color: #F5E4E0;
    font-family: 'Ranchers', cursive;
}
